import React, { useContext } from "react";
import NavigationItem from "../NavigationItem/NavigationItem";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  AcademicCapIcon,
  TrophyIcon,
  ClockIcon,
  UserCircleIcon,
  PlayIcon,
  ListBulletIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";

import { UserLevelContext } from "../../context/UserLevelContext";
import { CompanyTypeContext } from "../../context/CompanyTypeContext";


export default function Navigation({
  userLoggedIn,
  selectedIndex,
  setSelectedIndex,
  vesselId
}) {
  const navigate = useNavigate();
  const userLevel = useContext(UserLevelContext);
  const companyType = useContext(CompanyTypeContext);

  const clickedNavigationItem = (href, index) => {
    setSelectedIndex(index);
    navigate(href);
  };

  return (
    <>
      {userLoggedIn && (
        <>
          <NavigationItem
            name="Home"
            Icon={HomeIcon}
            onClicked={() => clickedNavigationItem("/home", 0)}
            isCurrent={selectedIndex === 0}
          />

          {(userLevel === 1 || userLoggedIn.showTraining) && (
            <>
              <h3
                className="px-3 pt-5 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                id="projects-headline"
              >
                Training Course
              </h3>

              <NavigationItem
                name="My Course"
                Icon={AcademicCapIcon}
                setSelectedIndex={setSelectedIndex}
                onClicked={() => clickedNavigationItem("/course", 1)}
                isCurrent={selectedIndex === 1}
              />
              <NavigationItem
                name="My History"
                Icon={ClockIcon}
                setSelectedIndex={setSelectedIndex}
                onClicked={() => clickedNavigationItem("/history", 2)}
                isCurrent={selectedIndex === 2}
              />
              <NavigationItem
                name="My Certifications"
                Icon={TrophyIcon}
                setSelectedIndex={setSelectedIndex}
                onClicked={() => clickedNavigationItem("/certifications", 3)}
                isCurrent={selectedIndex === 3}
              />

            </>
          )}

          {(userLevel === 1 || userLoggedIn.showTraining || userLoggedIn.showMyVideos) && (

            <NavigationItem
              name="My Videos"
              Icon={PlayIcon}
              setSelectedIndex={setSelectedIndex}
              onClicked={() => clickedNavigationItem("/video-library", 4)}
              isCurrent={selectedIndex === 4}
            />
          )}

          {userLevel >= 2 && (
            <>
              <h3
                className="px-3 pt-5 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                id="projects-headline"
              >
                Management
              </h3>
              <NavigationItem
                name={`My Users`}
                Icon={UserCircleIcon}
                setSelectedIndex={setSelectedIndex}
                onClicked={() => clickedNavigationItem("/users", 5)}
                isCurrent={selectedIndex === 5}
              />

              {userLevel >= 3 && companyType === "trainingAndManagement" && (
                <NavigationItem
                  name="My Fleet"
                  Icon={ListBulletIcon}
                  setSelectedIndex={setSelectedIndex}
                  onClicked={() => clickedNavigationItem("/fleet", 6)}
                  isCurrent={selectedIndex === 6}
                />
              )}

              {userLevel == 2 && companyType === "trainingAndManagement" && (
                <NavigationItem
                  name="My Vessel"
                  Icon={ListBulletIcon}
                  setSelectedIndex={setSelectedIndex}
                  onClicked={() => clickedNavigationItem(`/vessel/${vesselId}`, 7)}
                  isCurrent={selectedIndex === 7}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
