import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../services/utils";
import axios from "axios";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import PageHeader from "../../components/PageHeader/PageHeader";
import useUploadUsers from "../../data/hooks/useUploadUsers";

export default function ImportUsers({ userLoggedIn }) {
  let queryClient = useQueryClient();
  let uploadUserMutation = useUploadUsers();

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [file, setFile] = useState();

  useEffect(() => {
    if (uploadUserMutation.isSuccess) {
      setFile(null);
      navigate("/users");
    }
  }, [uploadUserMutation.isSuccess]);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const doUpload = () => {
    const formData = new FormData();
    formData.append("file", file);

    uploadUserMutation.mutate({ formData });
  };

  return (
    <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
      <PageHeader title="Import Users" />
      <div className="mt-4">
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="bg-white px-4 py-5 sm:p-6">
            <div className="text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                />
              </svg>

              <h2 className="mt-2 text-lg font-medium text-gray-900">
                Download Sample CSV Template
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                Simply download the sample CSV template for adding bulk users to
                the system. Please remove the demo data and replace with
                employee data before uploading. Employees will receive an invite
                email. (Maximum 100 users per file)
              </p>
              <a
                href="/CSV_Import_Users_Template.csv"
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <button
                  type="button"
                  className="inline-flex items-center mt-3 px-2.5 py-1.5 ml-3 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-500 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-3 h-3 mr-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  Download
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="bg-white px-4 py-5 sm:p-6">
            <div className="text-center">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5"
                />
              </svg>

              <h2 className="mt-2 text-lg font-medium text-gray-900">
                Upload Users to {userLoggedIn.company.CompanyName}
              </h2>
              <div className="mt-5 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-medium text-fathom-blue focus-within:outline-none focus-within:ring-2 focus-within:ring-fathom-blue focus-within:ring-offset-2 hover:text-fathom-blue"
                    >
                      <span>{!file ? "Upload a CSV file" : file.name}</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                        onChange={handleFileChange}
                        accept={".csv"}
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-gray-500 italic">
                    (.csv files only up to 50MB)
                  </p>
                </div>
              </div>
              <button
                onClick={doUpload}
                className="inline-flex justify-center rounded-md border border-transparent bg-fathom-blue mt-5 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-fathom-blue focus:ring-offset-2"
              >
                Import Users
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
