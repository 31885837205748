import { Fragment, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCertificate } from "@fortawesome/free-solid-svg-icons";
import LadderProgressCircle from "./LadderProgressCircle";
import { classNames } from "../../services/utils";

export default function Ladder({
  ladderData,
  ladderPos,
  showAddLadder,
  showRemoveLadder,
  showUseLadder,
  getLadderDocument,
  showInspectLadder,
  showInstallSpareLadder,
  maxLadderLife,
}) {
  const calcLadderElapsedPercentage = (startDate, maxLadderLife) => {
    if (startDate) {
      let startDateObj = new Date(startDate);
      let today = new Date();
      let diffInMonths =
        (today.getFullYear() - startDateObj.getFullYear()) * 12 +
        (today.getMonth() - startDateObj.getMonth());
      let elapsedPercentage = (diffInMonths / maxLadderLife) * 100;
      return elapsedPercentage > 100 ? 100 : elapsedPercentage;
    } else {
      return 0;
    }
  };

  const calcLadderElapsedMonths = (startDate) => {
    if (startDate) {
      let startDateObj = new Date(startDate);
      let today = new Date();
      return (
        (today.getFullYear() - startDateObj.getFullYear()) * 12 +
        (today.getMonth() - startDateObj.getMonth())
      );
    } else {
      return 0;
    }
  };

  const calcLadderExpiryDate = (manufactureDate, maxLadderLife) => {
    const manufacture = new Date(manufactureDate);
    const expiry = new Date(
      manufacture.setMonth(manufacture.getMonth() + maxLadderLife)
    );

    return expiry.toLocaleDateString();
  };

  return (
    <>
      {ladderData ? (
        <div>
          <div className="p-4 flex items-center justify-center">
            <div
              className={classNames(
                "w-6 h-6 mr-1  rounded-full",
                ladderPos === 1
                  ? "bg-red-500"
                  : ladderPos === 2
                  ? "bg-gray-500"
                  : "bg-green-500"
              )}
            ></div>
            <h2 className="text-xl font-semibold">
              {ladderPos === 1
                ? "Port"
                : ladderPos === 2
                ? "Spare"
                : "Starboard"}
            </h2>
          </div>
          <p className="text-xs text-center text-gray-500">
            Serial Number <br></br>
            {ladderData.serialNo}
          </p>
          <p className="text-xs text-center text-gray-500">
            Expiry:{" "}
            {calcLadderExpiryDate(ladderData.manufactureDate, maxLadderLife)}
          </p>
          {ladderData.certificateFile && (
            <p
              className="text-xs text-center text-gray-500 hover:cursor-pointer hover:underline"
              onClick={() => getLadderDocument(ladderPos)}
            >
              <FontAwesomeIcon
                icon={faCertificate}
                className="text-fathom-blue mr-1"
              />
              View Certificate
            </p>
          )}

          <LadderProgressCircle
            outerPercentage={calcLadderElapsedPercentage(
              ladderData.manufactureDate,
              maxLadderLife
            )}
            innerPercentage={calcLadderElapsedPercentage(
              ladderData.installationDate,
              maxLadderLife
            )}
            text={`${calcLadderElapsedMonths(
              ladderData.manufactureDate
            )}/${calcLadderElapsedMonths(ladderData.installationDate)} Months`}
          />
          <div className="flex flex-wrap mt-2">
            {ladderPos != 2 && (
              <button
                className="m-1 flex-1 w-full items-center px-3 py-2 border border-transparent text-xs sm:text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                onClick={() => showUseLadder(ladderPos)}
              >
                Use
              </button>
            )}

            <>
              <button
                className="m-1 flex-1 w-full items-center px-3 py-2 border border-transparent text-xs sm:text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-red hover:bg-fathom-dark-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                onClick={() => showRemoveLadder(ladderPos)}
              >
                Remove
              </button>
              <button
                className="m-1 flex-1 w-full items-center px-3 py-2 border border-transparent text-xs sm:text-sm leading-4 font-medium rounded-md shadow-sm text-gray-700 bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                onClick={() => showInspectLadder(ladderPos)}
              >
                Inspect
              </button>
              {ladderPos === 2 && (
                <button
                  className="m-1 flex-1 w-full items-center px-3 py-2 border border-transparent text-xs sm:text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                  onClick={() => showInstallSpareLadder(ladderPos)}
                >
                  Install
                </button>
              )}
            </>
          </div>
          {/* END Added Ladder */}
        </div>
      ) : (
        <div>
          {/* START Empty Ladder State */}
          <div className="flex justify-center h-full p-2">
            <div className="flex flex-col items-center justify-center border-dashed border-2 border-gray-300 rounded-lg p-6 flex-grow">
              <div className="p-1 flex items-center justify-center">
                <div
                  className={classNames(
                    "w-6 h-6 mr-1  rounded-full",
                    ladderPos === 1
                      ? "bg-red-500"
                      : ladderPos === 2
                      ? "bg-gray-500"
                      : "bg-green-500"
                  )}
                ></div>
                <h2 className="text-xl font-semibold">
                  {ladderPos === 1
                    ? "Port"
                    : ladderPos === 2
                    ? "Spare"
                    : "Starboard"}
                </h2>
              </div>
              <p className="text-sm text-gray-500 mb-4">No ladder set</p>
              <button
                className="px-4 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                onClick={() => showAddLadder(ladderPos)}
              >
                Add Ladder
              </button>
            </div>
          </div>
          {/* END Empty Ladder State */}
        </div>
      )}
    </>
  );
}
