import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";
import UserIcon from "@heroicons/react/24/outline/UserIcon";

export default function AddUserModal({
  showModal,
  setShowModal,
  companyType,
  vessels,
  onInviteSuccess,
}) {
  const cancelButtonRef = useRef(null);

  const [email, setEmail] = useState("");
  const queryClient = useQueryClient();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState("");

  const handleVesselChange = (event) => {
    setSelectedVessel(event.target.value);
  };

  // Mutations
  const addUserMutation = useMutation(
    () => {
      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/invites/adduser`,
        {
          email: email,
          vesselId: selectedVessel,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["users"]);
        onInviteSuccess(data);
        // Clear the form
        setEmail("");
        setSelectedVessel("");
      },
      onError: (error) => {
        setErrorMessage(
          "There was an error inviting the user. Please contact support."
        );
        setErrorDialogOpen(true);
      },
    }
  );

  const clickInvite = () => {
    setErrorList([]);
    let errors = [];
    if (email === "" || email === null || email === undefined) {
      errors.push("Email is required");
    }
    if (errors.length > 0) {
      setErrorMessage(errors.join(", "));
      setErrorDialogOpen(true);
    } else {
      addUserMutation.mutate();
      setShowModal(false);
    }
  };

  return (
    <div>
      <Transition.Root show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setShowModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-fathom-blue">
                    <UserIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Invite User
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        You can invite a user to your organisation enabling them
                        to access their course. Invited users will get an email
                        invitation and allow them to set a password and fill out
                        their own details.
                      </p>
                      <div className="col-span-6">
                        <input
                          type="email"
                          name="email"
                          id="email"
                          autoComplete="none"
                          className="mt-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                          value={email}
                          placeholder="Email"
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          }
                          required
                        />
                      </div>
                      {companyType === "trainingAndManagement" && (
                        <div className="col-span-6">
                          <select
                            value={selectedVessel}
                            onChange={handleVesselChange}
                            className="mt-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-fathom-blue focus:ring-fathom-blue sm:text-sm"
                          >
                            <option key={-1} value="">
                              No Vessel
                            </option>
                            {vessels &&
                              vessels.map((vessel) => (
                                <option key={vessel.id} value={vessel.id}>
                                  {vessel.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setShowModal(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-fathom-blue text-base font-medium text-white hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue sm:col-start-2 sm:text-sm"
                    onClick={clickInvite}
                  >
                    Invite
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
          <ErrorDialog
            open={errorDialogOpen}
            onClose={() => setErrorDialogOpen(false)}
            title="Error"
            onConfirm={() => setErrorDialogOpen(false)}
          >
            {errorMessage}
          </ErrorDialog>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
