import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import ErrorDialog from "../../components/Dialogs/ErrorDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWaterLadder } from "@fortawesome/free-solid-svg-icons";

export default function AddLadderModal({
  showModal,
  setShowModal,
  vesselId,
  ladderPos,
}) {
  const cancelButtonRef = useRef(null);
  const queryClient = useQueryClient();
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorList, setErrorList] = useState([]);

  // Define state variables for input fields
  const [serialNumber, setSerialNumber] = useState("");
  const [manufactureDate, setManufactureDate] = useState("");
  const [installationDate, setInstallationDate] = useState("");
  const [position, setPosition] = useState(
    ladderPos === 1 ? "PORT" : ladderPos === 2 ? "STARBOARD" : "SPARE"
  );
  const [retestDate, setRetestDate] = useState("");
  const [certificateFile, setCertificateFile] = useState(null);

  useEffect(() => {
    setSerialNumber("");
    setManufactureDate("");
    setInstallationDate("");
    setPosition(
      ladderPos === 1 ? "PORT" : ladderPos === 2 ? "SPARE" : "STARBOARD"
    );
    setRetestDate("");
    setCertificateFile(null);
  }, [ladderPos]);

  const handleCertificateChange = (e) => {
    const file = e.target.files[0];
    setCertificateFile(file);
  };

  // Get today's date
  const today = new Date().toISOString().split("T")[0];

  // Mutations
  const addLadderMutation = useMutation(
    () => {
      const formData = new FormData();

      formData.append("serialNo", serialNumber);
      formData.append("manufactureDate", manufactureDate);

      if (position !== "SPARE") {
        formData.append("installationDate", installationDate);
      }

      formData.append("position", position);
      formData.append("retestDate", retestDate);

      formData.append("vesselId", vesselId);
      formData.append("ladderPos", ladderPos);

      formData.append(`files.file`, certificateFile, certificateFile.name);

      let token =
        localStorage.getItem("user") &&
        JSON.parse(localStorage.getItem("user")).jwt;
      return axios.post(
        `${process.env.REACT_APP_API_ROOT}/ladders/add`,
        formData,
        {
          headers: {
            Accept: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        }
      );
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["user"]);
        // Clear form fields
        setSerialNumber("");
        setManufactureDate("");
        setInstallationDate("");
        setPosition(
          ladderPos === 1 ? "PORT" : ladderPos === 2 ? "STARBOARD" : "SPARE"
        );
        setRetestDate("");
        setCertificateFile(null);
      },
    }
  );

  const saveLadder = () => {
    setErrorList([]);
    let errors = [];

    if (
      serialNumber === "" ||
      serialNumber === null ||
      serialNumber === undefined
    ) {
      errors.push("Serial Number is required");
    }
    if (
      manufactureDate === "" ||
      manufactureDate === null ||
      manufactureDate === undefined
    ) {
      errors.push("Manufacture Date is required");
    }
    if (installationDate === "" && position !== "SPARE") {
      errors.push("Installation Date is required");
    }

    const selectedInstallDate = new Date(installationDate);
    const manufacturerDate = new Date(manufactureDate);
    if (selectedInstallDate < manufacturerDate) {
      errors.push("Installation Date cannot be prior to Manufacture Date");
    }

    if (
      certificateFile === "" ||
      certificateFile === null ||
      certificateFile === undefined
    ) {
      errors.push("Certificate is required and must be either an image or PDF");
    }

    if (errors.length > 0) {
      setErrorMessage(errors.join(", "));
      setErrorDialogOpen(true);
    } else {
      addLadderMutation.mutate();
      setShowModal(false);
    }
  };

  return (
    <>
      <div>
        <Transition.Root show={showModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={setShowModal}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div>
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-fathom-blue">
                      <FontAwesomeIcon
                        icon={faWaterLadder}
                        className="h-6 w-6 text-white"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Add Ladder
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5">
                    <label
                      htmlFor="serialNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Serial Number
                    </label>
                    <input
                      type="text"
                      name="serialNumber"
                      id="serialNumber"
                      value={serialNumber}
                      onChange={(e) => setSerialNumber(e.target.value)}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>

                  <div className="mt-5">
                    <label
                      htmlFor="manufactureDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date of Manufacture
                    </label>
                    <input
                      type="date"
                      name="manufactureDate"
                      id="manufactureDate"
                      value={manufactureDate}
                      onChange={(e) => setManufactureDate(e.target.value)}
                      max={
                        new Date(new Date().setDate(new Date().getDate() - 1))
                          .toISOString()
                          .split("T")[0]
                      }
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>
                  {position !== "SPARE" && (
                    <div className="mt-5">
                      <label
                        htmlFor="installationDate"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Date of Installation
                      </label>
                      <input
                        type="date"
                        name="installationDate"
                        id="installationDate"
                        value={installationDate}
                        onChange={(e) => setInstallationDate(e.target.value)}
                        max={today}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                        disabled={position === "SPARE"}
                      />
                    </div>
                  )}

                  <div className="mt-5">
                    <label
                      htmlFor="certificate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Certificate (Image or PDF)
                    </label>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      name="file"
                      id="file"
                      onChange={handleCertificateChange}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-fathom-blue focus:border-fathom-blue sm:text-sm"
                    />
                  </div>

                  <div className="mt-5 flex justify-end">
                    <button
                      type="button"
                      className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300"
                      onClick={() => setShowModal(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-fathom-blue hover:bg-fathom-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fathom-blue-dark"
                      onClick={saveLadder}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
            <ErrorDialog
              open={errorDialogOpen}
              onClose={() => setErrorDialogOpen(false)}
              title="Error"
              onConfirm={() => setErrorDialogOpen(false)}
            >
              {errorMessage}
            </ErrorDialog>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
}
