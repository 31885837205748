import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Navigation from "../../components/Navigation/Navigation";
import { HomeIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../services/utils";
import { useNavigate } from "react-router-dom";

const navigation = [{ name: "Home", href: "#", icon: HomeIcon, current: true }];

export default function NavigationWrapped({
  children,
  userLoggedIn,
  username,
  selectedIndex,
  setSelectedIndex,
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const setSelectedIndexMob = (index) => {
    setSelectedIndex(index);
    setSidebarOpen(false);
  };
  const doLogOut = () => {
    localStorage.removeItem("user");
    document.location.href = "/login";
  };

  const goProfile = () => {
    setSelectedIndex(-1);
    navigate("/profile");
    setSidebarOpen(false);
  };

  const goSettings = () => {
    setSelectedIndex(-1);
    navigate("/settings");
    setSidebarOpen(false);
  };

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>

              {/* Mobile Nav? */}
              <div className="flex-1 flex flex-col min-h-0 bg-white border-r border-gray-200 pt-5">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="mx-auto h-12 w-auto"
                    srcset="/images/logo_w500.png 2x /images/logo_w750.png 3x"
                    src="/images/logo_w250.png"
                    alt="Fathom Safety"
                  />
                </div>
                <nav className="mt-5 px-2 space-y-1">
                  <Navigation
                    companyType={
                      userLoggedIn.company && userLoggedIn.company.companyType
                    }
                    userLevel={userLoggedIn.userLevel}
                    userLoggedIn={userLoggedIn}
                    moduleList={[]}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndexMob}
                    vesselId={userLoggedIn.vessel?.id}
                  />
                </nav>
              </div>
              <div className="flex-shrink-0 flex bg-gray-100 p-4">
                <a href="#" className="flex-shrink-0 group block">
                  <div className="flex items-center">
                    <div>
                      <span className="inline-block relative">
                        <div
                          className="h-10 w-10 inline-flex overflow-hidden relative justify-center items-center bg-gray-100 rounded-full dark:bg-gray-600"
                          onClick={goProfile}
                        >
                          <span className="font-medium text-gray-600 dark:text-gray-300">
                            {username &&
                              username.split(" ")[0].substr(0, 1) +
                                (username.split(" ")[1] &&
                                  username.split(" ")[1].substr(0, 1))}
                          </span>
                        </div>
                        <span className="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span>
                      </span>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium cursor-default">
                        {username}
                      </p>
                      <p className="text-xs font-medium text-gray-500 hover:text-gray-600">
                        <span onClick={goProfile}>Edit Profile</span>

                        {userLoggedIn.userLevel >= 3 && (
                          <>
                            {" "}
                            | <span onClick={goSettings}>Settings</span>
                          </>
                        )}
                      </p>
                      <p
                        className="text-xs font-medium text-gray-500 hover:text-gray-600"
                        onClick={doLogOut}
                      >
                        Logout
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex-1 flex flex-col min-h-0 bg-white border-r border-gray-200">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-2">
              <img
                className="mx-auto w-auto"
                srcSet="/images/logo_w500.png 2x /images/logo_w750.png 3x"
                src="/images/logo_w250.png"
                alt="LADRZ"
              />
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-1">
              <Navigation
                userLoggedIn={userLoggedIn}
                moduleList={[]}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                vesselId={userLoggedIn.vessel?.id}
              />
            </nav>
          </div>
          <div className="flex-shrink-0 flex bg-white p-4 border-t border-gray-200">
            <a href="#" className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div>
                  <span className="inline-block relative">
                    <div
                      className="h-10 w-10 inline-flex overflow-hidden relative justify-center items-center bg-fathom-blue rounded-full"
                      onClick={goProfile}
                    >
                      <span className="font-medium text-white">
                        {username &&
                          username.split(" ")[0].substr(0, 1) +
                            (username.split(" ")[1] &&
                              username.split(" ")[1].substr(0, 1))}
                      </span>
                    </div>
                    <span className="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white bg-green-400"></span>
                  </span>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium cursor-default">
                    {username}
                  </p>
                  <p className="text-xs font-medium text-gray-500 hover:text-gray-600">
                    <span onClick={goProfile}>Edit Profile</span>
                    {userLoggedIn.userLevel >= 3 && (
                      <>
                        {" "}
                        | <span onClick={goSettings}>Settings</span>
                      </>
                    )}
                  </p>
                  <p
                    className="text-xs font-medium text-gray-500 hover:text-gray-600"
                    onClick={doLogOut}
                  >
                    Logout
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <div className="py-4">{children}</div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
