import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../services/utils";
import AddUpdateVesselModal from "./AddUpdateVesselModal";
import PageHeader from "../../components/PageHeader/PageHeader";
import MyFleetTable from "./FleetTable";
import MyFleetGrid from "./FleetGrid";
import { CompanyContext } from "../../context/CompanyContext";

export default function Fleet() {
  const company = useContext(CompanyContext);

  const [selectedTab, setSelectedTab] = useState(0);
  const [showAddVesselModal, setShowAddVesselModal] = useState(false);
  const [vessels, setVessels] = useState([]);

  useEffect(() => {
    if (company && company.vessels) setVessels(company.vessels);
  }, [company]);

  const showAddVessel = () => {
    setShowAddVesselModal(true);
  };

  return (
    <>
      <div className="relative pb-5 border-b border-gray-200 sm:pb-0">
        <div className="sm:flex sm:justify-between sm:items-baseline ">
          <PageHeader title="My Fleet" />
          <div className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
            <button
              onClick={showAddVessel}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-fathom-blue hover:bg-fathom-dark-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
            >
              Add Vessel
            </button>
          </div>
        </div>

        <div className="mt-4 my-5">
          <div className="sm:hidden">
            <select
              id="current-tab"
              name="current-tab"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              onChange={(e) => setSelectedTab(e.target.value)}
            >
              <option value={0}>Grid View</option>
              <option value={1}>Table View</option>
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              <a
                href="#"
                onClick={() => setSelectedTab(0)}
                className={classNames(
                  selectedTab == 0
                    ? "border-fathom-blue text-fathom-blue"
                    : "border-transparent",
                  "text-gray-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current="page"
              >
                Grid View
              </a>
              <a
                href="#"
                onClick={() => setSelectedTab(1)}
                className={classNames(
                  selectedTab == 1
                    ? "border-fathom-blue text-fathom-blue"
                    : "border-transparent",
                  " text-gray-500 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                )}
              >
                Table View
              </a>
            </nav>
          </div>

          {selectedTab == 0 && (
            <div className="mt-4">
              <MyFleetGrid data={vessels} />
            </div>
          )}

          {selectedTab == 1 && (
            <div className="mt-4">
              <MyFleetTable data={vessels} />
            </div>
          )}
        </div>
      </div>
      <AddUpdateVesselModal
        showModal={showAddVesselModal}
        setShowModal={setShowAddVesselModal}
      />
    </>
  );
}
