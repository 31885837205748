import "./App.css";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import ConfirmationToast from "./components/ConfirmationToast/ConfirmationToast";
import Welcome from "./pages/Welcome/Welcome";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Forgot from "./pages/Forgot/Forgot";
import ResetPassword from "./pages/ResetPassword/Reset";
import Profile from "./pages/Profile/Profile";
import Settings from "./pages/Settings/Settings";
import Modules from "./pages/Modules/Modules";
import Module from "./pages/Module/Module";
import Certifications from "./pages/Certifications/Certifications";
import ModuleHistory from "./pages/ModuleHistory/ModuleHistory";
import ImportUsers from "./pages/ImportUsers/ImportUsers";
import Users from "./pages/Users/Users";
import VideoLibrary from "./pages/VideoLibrary/VideoLibrary";
import Fleet from "./pages/Fleet/Fleet";
import Vessel from "./pages/Vessel/Vessel";
import VesselGallery from "./pages/Vessel/VesselGallery";
import LadderHistory from "./pages/LadderHistory/LadderHistory";

import Navigation from "./components/Navigation/Navigation";
import NavigationWrapped from "./pages/NavigationWrapped/NavigationWrapped";

import { UserLevelContext } from "./context/UserLevelContext.js";
import { CompanyTypeContext } from "./context/CompanyTypeContext.js";
import { CompanyContext } from "./context/CompanyContext.js";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import AcceptInvitation from "./pages/AcceptInvitation/AcceptInvitation";

export default function App() {
  ReactGA.initialize("G-ET77X1J6ZZ");
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [userLoggedIn, setUserLoggedIn] = React.useState(true);
  const [username, setUserName] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [companyLogo, setCompanyLogo] = React.useState(null);
  const [modules, setModules] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [toastMessage, setToastMessage] = useState(null);
  const [toastCode, setToastCode] = useState(1);
  const [showConfirmationToast, setShowConfirmationToast] = useState(false);

  const displaySomeToast = (message, code = 1) => {
    setToastMessage(message);
    setToastCode(code);
    setShowConfirmationToast(true);
    setTimeout(() => {
      setShowConfirmationToast(false);
    }, 3000);
  };

  const { status, data, error, isFetching } = useQuery(["user"], () => {
    let token =
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).jwt;
    if (token) {
      return axios.get(
        `${process.env.REACT_APP_API_ROOT}/users/me?populate[user_module_progresses][populate][0]=module&populate[user_role]=true&populate[user_boarding_type]=true&populate[vessel]=true&populate[company][populate][0]=vessels.photo,logo,vessels.ladder1,vessels.ladder2,vessels.ladder3,vessels.ladder1.certificateFile,vessels.ladder2.certificateFile,vessels.ladder3.certificateFile,vessels.images,vessels.ancillary_equipments`,
        {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        }
      ).catch((error) => {
        console.log('Me Fetch Error')
        console.log(error.response.status);
        if(error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login");
        }
      });
    } else return null;
  });

  useEffect(() => {
    if (status == "success" && data && data.data) {
      setUserLoggedIn(data.data);
      setUserName(`${data.data.firstName} ${data.data.lastName}`);
      setCompany(data.data.company);
      setCompanyLogo(data.data.company.logo);
      setModules(data.data.user_module_progresses);
    }
  }, [status, data]);

  return (
    <>
      <ErrorBoundary>
        <UserLevelContext.Provider value={userLoggedIn?.userLevel}>
          <CompanyContext.Provider value={userLoggedIn?.company}>
            <CompanyTypeContext.Provider value={company?.companyType}>
              <Routes>
                <Route
                  path="/"
                  element={
                    !userLoggedIn ? (
                      <Welcome />
                    ) : (
                      <Navigate replace to="/home" />
                    )
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route
                  path="/acceptinvitation/:id"
                  element={<AcceptInvitation />}
                />
                <Route
                  path="/acceptinvitation/"
                  element={<AcceptInvitation />}
                />
                <Route
                  element={
                    <ProtectedRoute
                      isAllowed={localStorage.getItem("user")}
                      userLoggedIn={userLoggedIn}
                      username={username}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                    />
                  }
                >
                  <Route
                    path="/home"
                    element={
                      <Home
                        username={username}
                        company={company?.CompanyName}
                        modules={modules}
                        selectedIndex={selectedIndex}
                        setSelectedIndex={setSelectedIndex}
                        companyLogo={companyLogo}
                        userLoggedIn={userLoggedIn}
                      />
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <Profile
                        userLoggedIn={userLoggedIn}
                        displaySomeToast={displaySomeToast}
                      />
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <Settings
                        userLoggedIn={userLoggedIn}
                        displaySomeToast={displaySomeToast}
                      />
                    }
                  />
                  <Route
                    path="/course"
                    element={<Modules modules={modules} />}
                  />
                  <Route
                    path="/history"
                    element={<ModuleHistory modules={modules} />}
                  />
                  <Route
                    path="/certifications"
                    element={
                      <Certifications
                        userLoggedIn={userLoggedIn}
                        displaySomeToast={displaySomeToast}
                        setSelectedIndex={setSelectedIndex}
                      />
                    }
                  />
                  <Route
                    path="/module/:id"
                    element={<Module modules={modules} />}
                  />
                  <Route
                    path="/importusers"
                    element={<ImportUsers userLoggedIn={userLoggedIn} />}
                  />
                  <Route
                    path="/users"
                    element={
                      <Users
                        userLoggedIn={userLoggedIn}
                        companyType={company?.companyType}
                      />
                    }
                  />
                  <Route
                    path="/video-library"
                    element={<VideoLibrary modules={modules} />}
                  />
                  <Route path="/fleet" element={<Fleet />} />
                  <Route
                    path="/vessel/:id/ladder-history"
                    element={<LadderHistory />}
                  />
                  <Route path="/vessel/:id" element={<Vessel />} />
                  <Route
                    path="/vessel/:id/gallery"
                    element={<VesselGallery />}
                  />
                </Route>

                <Route
                  path="*"
                  element={
                    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                      <div className="mx-auto max-w-screen-sm text-center">
                        <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600">
                          404
                        </h1>
                        <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
                          Something's missing.
                        </p>
                        <p className="mb-4 text-lg font-light text-gray-500">
                          Sorry, we can't find the page you requested.
                        </p>
                        <a
                          href="/home"
                          className="inline-flex text-fathom-blue bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                          Back to Dashboard
                        </a>
                      </div>
                    </div>
                  }
                />
              </Routes>
            </CompanyTypeContext.Provider>
          </CompanyContext.Provider>
        </UserLevelContext.Provider>
        {showConfirmationToast && (
          <ConfirmationToast
            message={toastMessage}
            toastCode={toastCode}
            setShowToast={setShowConfirmationToast}
          />
        )}
      </ErrorBoundary>
    </>
  );
}

const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/login",
  userLoggedIn,
  children,
  username,
  selectedIndex,
  setSelectedIndex,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }
  return (
    <NavigationWrapped
      userLoggedIn={userLoggedIn}
      username={username}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
    >
      {children ? children : <Outlet />}
    </NavigationWrapped>
  );
};
