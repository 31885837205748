import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import getImage from "../../components/GetImage/GetImage";
import ArrowLeftIcon from "@heroicons/react/24/outline/ArrowLeftIcon";
import ArrowRightIcon from "@heroicons/react/24/outline/ArrowRightIcon";
import XIcon from "@heroicons/react/24/outline/XMarkIcon";
import { getVesselGallery } from "../../services/strapiService";
import { useQuery } from "@tanstack/react-query";

export default function VesselGallery() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedTab, setSelectedTab] = useState("imagesPort");

  const vesselGallery = useQuery(["gallery", id], () => getVesselGallery(id));

  useEffect(() => {
    if (vesselGallery.data?.data && vesselGallery.isSuccess) {
      setData(vesselGallery.data.data);
    }
  }, [id, vesselGallery]);

  const openModal = (id) => {
    const selectedIndex = data[selectedTab].findIndex(
      (image) => image.id === id
    );
    setSelectedImageIndex(selectedIndex);
    setOpen(true);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
    setOpen(false);
  };

  const goToNext = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex + 1) % data[selectedTab].length
    );
  };

  const goToPrevious = () => {
    setSelectedImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + data[selectedTab].length) % data[selectedTab].length
    );
  };

  const tabs = [
    { key: "imagesPort", label: "Port Ladder" },
    { key: "imagesStarboard", label: "Starboard Ladder" },
    { key: "imagesSpare", label: "Spare Ladder" },
    { key: "imagesAncillary", label: "Ancillary Equipment" },
    { key: "imagesOther", label: "Other" },
  ];

  const handleTabChange = (key) => {
    setSelectedTab(key);
  };

  return (
    <div>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200">
        <ul className="flex flex-wrap -mb-px">
          {tabs.map((tab) => (
            <li key={tab.key}>
              <button
                onClick={() => handleTabChange(tab.key)}
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  selectedTab === tab.key
                    ? "border-fathom-blue text-fathom-blue"
                    : "border-transparent hover:text-gray-600 hover:border-gray-300"
                }`}
              >
                {tab.label}{" "}
                <span class="inline-flex items-center justify-center w-5 h-5 text-xs font-semibold text-white bg-fathom-blue rounded-full">
                  {data && data[tab.key]?.length ? data[tab.key].length : 0}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-4">
        {data && data[selectedTab] && data[selectedTab].length > 0 ? (
          data[selectedTab].map((image, index) => (
            <div key={index} className="relative">
              <img
                src={getImage({
                  image: image,
                })}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => openModal(image.id)}
                className="cursor-pointer w-full h-full object-cover"
              />
            </div>
          ))
        ) : (
          <p>No images found.</p>
        )}
        {/* Lightbox Modal */}
        <Transition show={open} as={React.Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModal}
          >
            <div className="min-h-screen flex items-center justify-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="inline-block align-middle">&#8203;</span>

              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-block w-full max-w-4xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg relative">
                  {selectedImageIndex !== null && (
                    <>
                      <img
                        src={getImage({
                          image: data[selectedTab][selectedImageIndex],
                          desiredFormat: "large",
                        })}
                        alt={`Full Size ${selectedImageIndex + 1}`}
                        className="max-h-screen w-full"
                      />
                      <button
                        className="absolute top-1/2 left-4 mt-4 text-gray-500 hover:text-gray-700 transform -translate-y-1/2 z-10"
                        onClick={goToPrevious}
                      >
                        <ArrowLeftIcon className="h-6 w-6" />
                      </button>
                      <button
                        className="absolute top-1/2 right-4 mt-4 text-gray-500 hover:text-gray-700 transform -translate-y-1/2 z-10"
                        onClick={goToNext}
                      >
                        <ArrowRightIcon className="h-6 w-6" />
                      </button>
                    </>
                  )}

                  <button
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 z-10"
                    onClick={closeModal}
                  >
                    <XIcon className="h-6 w-6" />
                  </button>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}
